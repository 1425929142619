<template>
  <div v-if="result.valueLine.c_type == 'Enumerated'">
    <attributeValueAdderContent
      :addable="true"
      :headerTitle="true"
      indexName="Value"
      v-model="diaEnum"
      :fields="fields"
      :isValid="isValid"
      linePrefix="dia_enum"
    ></attributeValueAdderContent>
    <input type="hidden" :value="JSON.stringify(localValue)" name="c_obj" />
  </div>
</template>
<script>
import attributeValueAdderContent from "@/components/legacy/attributeValueAdderContent.vue";
import lineButtons from "@/cfg/lineButtons.json";
export default {
  components: { attributeValueAdderContent },
  props: ["result", "fieldAttributes", "field", "value", "isValid"],
  data() {
    return {
      fields: {
        index: {
          type: "text",

          name: "Value",
          fieldAttrInput: { class: "required number" },
        },
        value: {
          type: "text",

          name: "Interpretation",
          fieldAttrInput: { class: "required" },
        },
        remove: lineButtons.remove,
      },
    };
  },
  computed: {
    localValue: {
      get() {
        let localValue;
        try {
          localValue = JSON.parse(this.value);
        } catch (error) {
          localValue = { diaEnum: {} };
        }
        return localValue;
      },
      set(newVal) {
        let val = JSON.stringify(newVal);
        this.$emit("input", val);
      },
    },
    diaEnum: {
      get() {
        return this.localValue.diaEnum;
      },
      set(newVal) {
        this.$set(this.localValue, "diaEnum", newVal);
        let val = JSON.stringify(this.localValue);
        this.$emit("input", val);
      },
    },
  },
};
</script>
<!--template><tr>
<td colspan=3>
{$id="scriptID"|cat:rand ( 0 , 10000 )}
<div class="diaEnumeratorGroup" id='{$id}'>
  <table style="" class="diaEnumerationTable">
    <tbody class="attributesBody">
    <tr style="background-color:#5c9ccc;color:white;height:18px;width:100% ">
      <td class="enumerationValue" style="padding-left: 5px;">{language("diameterAttributes","enumerationValue")}</td>
      <td style="enumerationInterpretation">{language("diameterAttributes","enumerationInterpretation")}</td>
      <td style="fieldPlaceHolder"></td>
      <td class='trashholder'></td>
    </tr>
    <tr style="height:5px;"><td></td></tr>
    <tr class="attributeRow defaultRowDiaEnumerator"><td class="value enumerationValue" style=""><input name="name_0" id="name_0" placeholder="" group="enumerator" class="attrName number required groupUnique" type="text" style="width:230px;" value="" autofocus="autofocus"></td><td class="value enumerationInterpretation"><input name="value_0" id="value_0" placeholder="" class="attrValue required" type="text" style="width:230px;" value=""></td><td style="fieldPlaceHolder"></td><td class="trashholder"><a class="deleteAttr" style="position: relative;left:0px;" onclick="deleteAttributes(this);return false;" title="Delete"><i class="fas fa-trash fs16 iconRed"></i></a></td></tr>
    <tr class="controlButt">
      <td>
        <button href="javascript:void(0);" onclick="constructAttribute(this);return false;" title="Add new" style="margin-top:1px;margin-left:5px;width:auto;" class="addDiaEnum newEntry"><span class="buttLabelWithIcon" style="padding-left: 7px;">Add Enumerated</span></button>
      </td>
    </tr>
    </tbody>
  </table>
</div>
<script id="{$id}">
  //Get value from DB
  var sourceVal = v-if="typeof $attributes.value)&& $attributes.value!="" && isJson($attributes.value!='undefined'"{$attributes.value}{else}""{/if};
  window.nameInc =1;
//Existing enumerator value load by following fns!
  $(function() {
    var $formField = $("#{$id}").closest(".fields");
    var fieldsObject=$formField.find('.controlButt');
    var select = $formField.find("select[name=c_type]");
    addDiaEnum(fieldsObject,sourceVal);
    if(select.val()=="Enumerated"){
      fieldsObject.closest("table").show();
      fieldsObject.closest('table').find('.defaultRowDiaEnumerator').hide();
    }
    resizeForm($formField);
    select.on('change', function() {
      let $formFields = $(this).closest(".fields");
      if(this.value=="Enumerated"){
        fieldsObject.closest("table").show();
      }else {
        fieldsObject.closest("table").hide();
      }
      resizeForm($formFields);
    })
  });

  function addDiaEnum(that,sourceVal){
    console.log(sourceVal);
    var $caller=$(that);
    var $attributesBody=$caller .closest("table").find(".attributesBody");
    if(sourceVal!=null) {
      getDiaEnumCommand(sourceVal, $attributesBody, that);
    }
  }

  function getDiaEnumCommand(attrvalues,$tbody,that){
    if ( $.isEmptyObject(attrvalues.diaEnum)===false && typeof attrvalues.diaEnum != 'undefined' ) {  // Avoid empty object !
      $.each(attrvalues.diaEnum, function (index, value) {
        constructAttribute(that, index, value);
      });
    }
  }
  function constructAttribute(that,index,value){
    console.log(index);
    var $caller=$(that);
    var $attributesBody=$caller .closest("table").find(".attributesBody");
    if ((typeof index == 'undefined' || index == '')&& (typeof value == 'undefined' || value == '') ) {
      index= "" ;
      value= "" ;
    }
    window.nameInc++
    var $row=$("<tr class='attributeRow'></tr>").appendTo($attributesBody).after(that);
  var $input=$("<td class='value enumerationValue' style=''><input name='name_"+window.nameInc+"' id='name_"+window.nameInc+"' placeholder='' group='enumerator' class='attrName number required groupUnique' type='text' style='width:230px;' value='"+index+"'></td><td class='value enumerationInterpretation'><input name='value_"+window.nameInc+"' id='value_"+window.nameInc+"' placeholder='' class='attrValue required' type='text' style='width:230px;' value='"+value+"' ></td><td style='fieldPlaceHolder'></td><td class='trashholder'><a class='deleteAttr' style='position: relative;left:0px;' onclick='deleteAttributes(this);return false;' title='Delete'><i class='fas fa-trash fs16 iconRed' ></i></a></td>").appendTo($row);
    let $formFields = $caller.closest(".fields");
    resizeForm($formFields);
  }
  function deleteAttributes(that){
    var $caller=$(that);
    let $formFields = $caller.closest(".fields");
    resizeForm($formFields);
    $caller.closest('.attributeRow').remove();
  }
  function parseDiaEnum(fieldsObject,data){
    var $fieldsObject=$(fieldsObject);
    var requestsArray={};
   // var requestsArray=[];
    $fieldsObject.find('.attributeRow').each(function(){
      var $attributeRow=$(this).find(".value");
      var enumName=$attributeRow.find('.attrName').val();
      var enumValue=$attributeRow.find('.attrValue').val();
      var diaEnumJSON={  };
      if(enumName!='' && enumValue!=""){
        requestsArray[enumName]=enumValue;
      }
    });
    var diaObject={ "diaEnum":requestsArray};
    data["c_obj"]=diaObject
    return data;
  }
</script>
</td>
</tr></template-->